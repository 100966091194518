import React from 'react';
import LanguageSelector from './LanguageSelector';
import { GameName } from './GameName';

const MainScreen = () => {
  return (
    <div className='main-screen'>
      <GameName />
      <LanguageSelector />
    </div>
  );
};

export default MainScreen;
