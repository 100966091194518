import React, { FC } from 'react';
import { GAMES } from '../constant/constants';
import { Game } from '../interfaces/common.interface';

import { useTranslation } from 'react-i18next';

export const GameName: FC = () => {
  const { t } = useTranslation();

  const handleOpenGame = (root: string) => {
    window.open(root, '_self');
  };
  return (
    <div className='name-container'>
      {GAMES.map((item: Game) => (
        <h1 onClick={() => handleOpenGame(item.root)}>
          {t(`${item.i18nextName}`)}
        </h1>
      ))}
    </div>
  );
};
