import './App.css';
import MainScreen from './components/MainScreen';
import LanguageContextProvider from './context/languageContext';

function App() {
  return (
    <LanguageContextProvider>
      <div className='App'>
        <MainScreen />
      </div>
    </LanguageContextProvider>
  );
}

export default App;
