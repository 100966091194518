import React, { FC, useContext } from 'react';
import { LANGUEAGES } from '../constant/constants';
import { LanguageInterface } from '../interfaces/common.interface';

import cannon from '../assets/images/agyu.png';
import { LanguageContext } from '../context/languageContext';

const LanguageSelector: FC = () => {
  const languageCtx = useContext(LanguageContext);
  console.log(languageCtx);
  return (
    <div className='bottom-box'>
      <img src={cannon} alt='' />
      <div className='language-box'>
        {LANGUEAGES.map((item: LanguageInterface) => (
          <p
            className={`language-name ${
              languageCtx?.language === item.i18next ? 'active' : ''
            }`}
            onClick={()=> {languageCtx?.setLanguage(item.i18next)}}
          >
            {item.name}
          </p>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelector;
