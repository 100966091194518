export const LANGUEAGES = [
  {
    name: 'MAGYAR',
    i18next: 'hu',
  },
  {
    name: 'ROMÂNĂ',
    i18next: 'ro',
  },
  {
    name: 'ENGLISH',
    i18next: 'en',
  },
]


export const GAMES = [
  {
    i18nextName: 'soldier',
    root: '/katona'
  },
  {
    i18nextName: 'lice',
    root: '/tetufuttato'
  }
]